/* Taquilla en Línea */

// Bootstrap Functions
@import "../../../node_modules/bootstrap/scss/functions";

// Theme Variables
@import "argon-design-system/variables";

// Bootstrap Core
@import "../../../node_modules/bootstrap/scss/bootstrap";

// Theme Core
@import "argon-design-system/theme";

// React Differences
@import "react/react-differences";
