@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");

*,
*:after,
*:before {
	box-sizing: border-box;
}

.list-item {
	border-bottom: 1px solid #ccc; /* Color y grosor del borde */
}  

.checkbox-group {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 90%;
	margin-left: auto;
	margin-right: auto;
	max-width: 600px;
	user-select: none;
	& > * {
		margin: .5rem 0.5rem;
	}
}


.checkbox-group-legend {
	font-size: 1.5rem;
	font-weight: 700;
	color: #9c9c9c;
	text-align: center;
	line-height: 1.125;
	margin-bottom: 1.25rem;
}

.checkbox-input {
	/* // Code to hide the input */
	clip: rect(0 0 0 0);
	clip-path: inset(100%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;

	&:checked + .checkbox-tile {
		border-color: #2260ff;
		box-shadow: 0 5px 10px rgba(#000, 0.1);
		color: #2260ff;
		&:before {
			transform: scale(1);
			opacity: 1;
			background-color: #2260ff;
			border-color: #2260ff;
		}
		
		.checkbox-icon, .checkbox-label {
			color: #2260ff;
		}
	}
	
	&:focus + .checkbox-tile {
		border-color: #2260ff;
		box-shadow: 0 5px 10px rgba(#000, 0.1), 0 0 0 4px #b5c9fc;
		&:before {
			transform: scale(1);
			opacity: 1;
		}
	}
}

.checkbox-tile {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 7rem;
	min-height: 7rem;
	border-radius: 0.5rem;
	border: 2px solid #b5bfd9;
	background-color: #fff;
	box-shadow: 0 5px 10px rgba(#000, 0.1);
	transition: 0.15s ease;
	cursor: pointer;
	position: relative;

	&:before {
		content: "";
		position: absolute;
		display: block;
		width: 1.25rem;
		height: 1.25rem;
		border: 2px solid #b5bfd9;
		background-color: #fff;
		border-radius: 50%;
		top: 0.25rem;
		left: 0.25rem;
		opacity: 0;
		transform: scale(0);
		transition: 0.25s ease;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
		background-size: 12px;
		background-repeat: no-repeat;
		background-position: 50% 50%;
	}

	&:hover {
		border-color: #2260ff;
		&:before {
			transform: scale(1);
			opacity: 1;
		}
	}
}

.checkbox-icon {
	transition: .375s ease;
	color: #494949;
	svg {
		width: 3rem;
		height: 3rem;
	}
}

.checkbox-label {
	color: #707070;
	transition: .375s ease;
	text-align: center;
}


/*Estilos de los botones de Eventos*/
.custom-dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-button {
    background-color: #4caf50;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 10%;
    width: 300px;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    /* min-width: 160px; */
    /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
    z-index: 1;
  }
  
  .dropdown-content button {
    display: block;
    padding: 10px;
    text-align: left;
  }
  
  .dropdown-content button:hover {
    background-color: #f1f1f1;
  }
  
  .custom-dropdown:hover .dropdown-content {
    display: block;
  }

  .custom-button {
    width: 250px;
  }



  /*------------------------------ Hover ------------------------------*/

  .mouse:hover {
	background-color: whitesmoke;
  }


  .map {
	margin-left: auto;
	margin-right: auto;
  }


  .seat:hover{
	cursor:pointer;
  }

  .opacity-custom{
	opacity: 0.7;
  }

  .opacity-custom:hover{
	opacity: 1;
  }
  
  .dropdown {
	position: relative;
	display: inline-block;
  }

  .dropdown button{
	padding: 8px 20px;
	background-color: #fff;
	color: #B2BFCD;
	border: 1px solid #CAD1D7;
  }
  
  .dropdown ul {
	display: block;
	position: absolute;
	min-width: 110px;
	z-index: 1;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	background-color: #f9f9f9;
  }
  
  .dropdown ul li {
	color: black;
	padding: 4px 3px;
	margin-left: -35px;
	text-decoration: none;
	display: block;
	cursor: pointer;
  }
  
  .dropdown ul li:hover {
	background-color: #f1f1f1;
  }

  .acordeon-animado {
	transition: max-height 0.2s ease-in-out;
	color:#000
  }



/* Jgil 16/01/2024: Detalle del banco */
  .detalle-banco {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    margin: 10px 0;
    background-color: #f9f9f9;
}

.resumen-banco {
    color: #333;
    font-weight: bold;
    cursor: pointer;
    font-size: 1.1em;
}

.lista-banco {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.item-banco {
    margin-bottom: 5px;
    padding: 5px;
    border-bottom: 1px solid #eee;
}

.item-banco:last-child {
    border-bottom: none;
}

.destacado {
    color: #0056b3;
}
  /* ---------------------------------Input time--------------------------------- */

  .input-time-group {
	display: flex;
	border: 1px solid #ced4da;
	border-radius: .25rem;
	align-items: stretch; /* Asegura que los elementos se estiren para llenar el contenedor */
	width: 100%; /* Asegura que el contenedor tenga una altura */
  }
  
  .input-time-group select, 
  .input-time-group button {
	margin: 0;
	border: none;
	border-radius: 0; /* Elimina el borde y el radio del borde para elementos internos */
	flex: 1; /* Hace que los elementos internos se expandan para llenar el espacio */
  }
  
  .input-time-group select {
	padding: .375rem .75rem; /* Ajusta el padding para que coincida con Bootstrap */
  }
  
  .input-time-group button {
	background-color: white;
	cursor: pointer;
	border-left: 1px solid #ced4da; /* Añade un borde a la izquierda del botón para separarlo */
  }
  
  /* Estilos adicionales para el enfoque y el hover */
  .input-time-group select:focus, 
  .input-time-group button:focus, 
  .input-time-group button:hover {
	z-index: 2; /* Asegura que el elemento en foco se muestre sobre los demás */
  }
