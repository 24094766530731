.modal-content {
    border: 0;
    @if $enable-rounded {
        border-radius: $modal-content-border-radius;
    } @else {
        border-radius: 0;
    }  
}


.modal-fluid {
    .modal-dialog {
        margin-top: 0;
        margin-bottom: 0;
    }
    .modal-content {
        border-radius: 0;
    }
}

@each $color, $value in $theme-colors {
    .modal-#{$color} {
        @include modal-variant($value);
    }
}

.modal {
    background-color: rgba(0, 0, 0, 0.671);
    backdrop-filter: blur(3px);
}

.modal-header {
    padding: 10px !important;
}

.modal-title{
    margin-top: 5px;
}