/*--------------------------------

hermes-dashboard-icons Web Font - built using nucleoapp.com
License - nucleoapp.com/license/

-------------------------------- */
@font-face {
  font-family: 'NucleoIcons';
  src: url('../fonts/nucleo-icons.eot');
  src: url('../fonts/nucleo-icons.eot') format('embedded-opentype'), url('../fonts/nucleo-icons.woff2') format('woff2'), url('../fonts/nucleo-icons.woff') format('woff'), url('../fonts/nucleo-icons.ttf') format('truetype'), url('../fonts/nucleo-icons.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
/*------------------------
    base class definition
-------------------------*/
.ni {
  display: inline-block;
  font: normal normal normal 14px/1 NucleoIcons;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*------------------------
  change icon size
-------------------------*/
.ni-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.ni-2x {
  font-size: 2em;
}
.ni-3x {
  font-size: 3em;
}
.ni-4x {
  font-size: 4em;
}
.ni-5x {
  font-size: 5em;
}

/*----------------------------------
  add a square/circle background
-----------------------------------*/
.ni.square,
.ni.circle {
  padding: 0.33333333em;
  vertical-align: -16%;
  background-color: #eee;
}
.ni.circle {
  border-radius: 50%;
}
/*------------------------
  list icons
-------------------------*/
.ni-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none;
}
.ni-ul > li {
  position: relative;
}
.ni-ul > li > .ni {
  position: absolute;
  left: -1.57142857em;
  top: 0.14285714em;
  text-align: center;
}
.ni-ul > li > .ni.lg {
  top: 0;
  left: -1.35714286em;
}
.ni-ul > li > .ni.circle,
.ni-ul > li > .ni.square {
  top: -0.19047619em;
  left: -1.9047619em;
}
/*------------------------
  spinning icons
-------------------------*/
.ni.spin {
  -webkit-animation: nc-spin 2s infinite linear;
  -moz-animation: nc-spin 2s infinite linear;
  animation: nc-spin 2s infinite linear;
}
@-webkit-keyframes nc-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes nc-spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@keyframes nc-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*------------------------
  rotated/flipped icons
-------------------------*/
.ni.rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.ni.rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.ni.rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}
.ni.flip-y {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.ni.flip-x {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: scale(1, -1);
  -moz-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  transform: scale(1, -1);
}
/*------------------------
    font icons
-------------------------*/

.ni-active-40::before {
    content: "\ea02";
}

.ni-air-baloon::before {
    content: "\ea03";
}

.ni-album-2::before {
    content: "\ea04";
}

.ni-align-center::before {
    content: "\ea05";
}

.ni-align-left-2::before {
    content: "\ea06";
}

.ni-ambulance::before {
    content: "\ea07";
}

.ni-app::before {
    content: "\ea08";
}

.ni-archive-2::before {
    content: "\ea09";
}

.ni-atom::before {
    content: "\ea0a";
}

.ni-badge::before {
    content: "\ea0b";
}

.ni-bag-17::before {
    content: "\ea0c";
}

.ni-basket::before {
    content: "\ea0d";
}

.ni-bell-55::before {
    content: "\ea0e";
}

.ni-bold-down::before {
    content: "\ea0f";
}

.ni-bold-left::before {
    content: "\ea10";
}

.ni-bold-right::before {
    content: "\ea11";
}

.ni-bold-up::before {
    content: "\ea12";
}

.ni-bold::before {
    content: "\ea13";
}

.ni-book-bookmark::before {
    content: "\ea14";
}

.ni-books::before {
    content: "\ea15";
}

.ni-box-2::before {
    content: "\ea16";
}

.ni-briefcase-24::before {
    content: "\ea17";
}

.ni-building::before {
    content: "\ea18";
}

.ni-bulb-61::before {
    content: "\ea19";
}

.ni-bullet-list-67::before {
    content: "\ea1a";
}

.ni-bus-front-12::before {
    content: "\ea1b";
}

.ni-button-pause::before {
    content: "\ea1c";
}

.ni-button-play::before {
    content: "\ea1d";
}

.ni-button-power::before {
    content: "\ea1e";
}

.ni-calendar-grid-58::before {
    content: "\ea1f";
}

.ni-camera-compact::before {
    content: "\ea20";
}

.ni-caps-small::before {
    content: "\ea21";
}

.ni-cart::before {
    content: "\ea22";
}

.ni-chart-bar-32::before {
    content: "\ea23";
}

.ni-chart-pie-35::before {
    content: "\ea24";
}

.ni-chat-round::before {
    content: "\ea25";
}

.ni-check-bold::before {
    content: "\ea26";
}

.ni-circle-08::before {
    content: "\ea27";
}

.ni-cloud-download-95::before {
    content: "\ea28";
}

.ni-cloud-upload-96::before {
    content: "\ea29";
}

.ni-compass-04::before {
    content: "\ea2a";
}

.ni-controller::before {
    content: "\ea2b";
}

.ni-credit-card::before {
    content: "\ea2c";
}

.ni-curved-next::before {
    content: "\ea2d";
}

.ni-delivery-fast::before {
    content: "\ea2e";
}

.ni-diamond::before {
    content: "\ea2f";
}

.ni-email-83::before {
    content: "\ea30";
}

.ni-fat-add::before {
    content: "\ea31";
}

.ni-fat-delete::before {
    content: "\ea32";
}

.ni-fat-remove::before {
    content: "\ea33";
}

.ni-favourite-28::before {
    content: "\ea34";
}

.ni-folder-17::before {
    content: "\ea35";
}

.ni-glasses-2::before {
    content: "\ea36";
}

.ni-hat-3::before {
    content: "\ea37";
}

.ni-headphones::before {
    content: "\ea38";
}

.ni-html5::before {
    content: "\ea39";
}

.ni-istanbul::before {
    content: "\ea3a";
}

.ni-key-25::before {
    content: "\ea3b";
}

.ni-laptop::before {
    content: "\ea3c";
}

.ni-like-2::before {
    content: "\ea3d";
}

.ni-lock-circle-open::before {
    content: "\ea3e";
}

.ni-map-big::before {
    content: "\ea3f";
}

.ni-mobile-button::before {
    content: "\ea40";
}

.ni-money-coins::before {
    content: "\ea41";
}

.ni-note-03::before {
    content: "\ea42";
}

.ni-notification-70::before {
    content: "\ea43";
}

.ni-palette::before {
    content: "\ea44";
}

.ni-paper-diploma::before {
    content: "\ea45";
}

.ni-pin-3::before {
    content: "\ea46";
}

.ni-planet::before {
    content: "\ea47";
}

.ni-ruler-pencil::before {
    content: "\ea48";
}

.ni-satisfied::before {
    content: "\ea49";
}

.ni-scissors::before {
    content: "\ea4a";
}

.ni-send::before {
    content: "\ea4b";
}

.ni-settings-gear-65::before {
    content: "\ea4c";
}

.ni-settings::before {
    content: "\ea4d";
}

.ni-single-02::before {
    content: "\ea4e";
}

.ni-single-copy-04::before {
    content: "\ea4f";
}

.ni-sound-wave::before {
    content: "\ea50";
}

.ni-spaceship::before {
    content: "\ea51";
}

.ni-square-pin::before {
    content: "\ea52";
}

.ni-support-16::before {
    content: "\ea53";
}

.ni-tablet-button::before {
    content: "\ea54";
}

.ni-tag::before {
    content: "\ea55";
}

.ni-tie-bow::before {
    content: "\ea56";
}

.ni-time-alarm::before {
    content: "\ea57";
}

.ni-trophy::before {
    content: "\ea58";
}

.ni-tv-2::before {
    content: "\ea59";
}

.ni-umbrella-13::before {
    content: "\ea5a";
}

.ni-user-run::before {
    content: "\ea5b";
}

.ni-vector::before {
    content: "\ea5c";
}

.ni-watch-time::before {
    content: "\ea5d";
}

.ni-world::before {
    content: "\ea5e";
}

.ni-zoom-split-in::before {
    content: "\ea5f";
}

.ni-collection::before {
    content: "\ea60";
}

.ni-image::before {
    content: "\ea61";
}

.ni-shop::before {
    content: "\ea62";
}

.ni-ungroup::before {
    content: "\ea63";
}

.ni-world-2::before {
    content: "\ea64";
}

.ni-ui-04::before {
    content: "\ea65";
}


/* all icon font classes list here */
.header-banner {
    /* border-radius: 15px 15px;
    background-repeat: no-repeat;
    background-position: center center;
    height: 100vh; */
    height: 100vh;;
    width: auto;
    /* grid-template-columns: 30% auto;
    object-fit: cover; */
}

.header-banner > img {
    object-fit: cover;
    width:100%;
    height:100%;
    border-radius: 15px 15px;
}

@media (min-width: 320px) {
    .banner-button {
        position: absolute;
        bottom: 10vh;
        right: 2vw;
        z-index: 10;
        width: 84vw !important;
    }

    .banner-button_2 {
        position: absolute;
        bottom: 10vh;
        right: 2vw;
        /* z-index: 10; */
        width: 84vw !important;
    }

    .container-content {
        padding-left: 41px !important;
        padding-right: 41px !important;
        margin-top: 80px !important;
    }

    .custom-mt-media-10 {
        margin-top: 10px;
    }

    .custom-padding-media-l {
        padding-left: 48px !important;
        padding-right: 46px !important;
    }

    .custom-padding-media-r {
        padding-left: 44px !important;
        padding-right: 80px !important;
    }

    .custom-height {
        min-height: 1825px;
    }
}

@media (min-width: 576px) {
    .banner-button {
        position: absolute;
        bottom: 10vh;
        right: 3vw;
        z-index: 10;
        width: 82vw !important;
    }

    .banner-button_2 {
        position: absolute;
        bottom: 10vh;
        right: 3vw;
        /* z-index: 10; */
        width: 82vw !important;
    }

    .container-content {
        padding-left: 58px !important;
        padding-right: 58px !important;
        margin-top: 80px !important;
    }

    .custom-mt-media-10 {
        margin-top: 10px;
    }

    .custom-padding-media-l {
        padding-left: 53px !important;
        padding-right: 53px !important;
    }

    .custom-padding-media-r {
        padding-left: 53px !important;
        padding-right: 53px !important;
    }

    .custom-height {
        min-height: 1900px;
    }
}
  
@media (min-width: 768px) {
    .banner-button {
        position: absolute;
        bottom: 10vh;
        right: 18vw;
        z-index: 10;
        width: 50vw !important;
    }

    .banner-button_2 {
        position: absolute;
        bottom: 10vh;
        right: 18vw;
        /* z-index: 10; */
        width: 50vw !important;
    }

    .container-content {
        padding-left: 69px !important;
        padding-right: 69px !important;
        margin-top: 80px !important;
    }

    .custom-mt-media-10 {
        margin-top: 0px;
    }

    .custom-padding-media-l {
        padding-left: 65px !important;
        padding-right: 65px !important;
    }

    .custom-padding-media-r {
        padding-left: 65px !important;
        padding-right: 65px !important;
    }

    .custom-height {
        min-height: 1730px;
    }
}
  
@media (min-width: 992px) {
    .banner-button {
        position: absolute;
        bottom: 29vh;
        right: 10vw;
        z-index: 10;
        width: 30vw !important;
    }

    .banner-button_2 {
        position: absolute;
        bottom: 29vh;
        right: 10vw;
        /* z-index: 10; */
        width: 30vw !important;
    }

    .container-content {
        padding-left: 76px !important;
        padding-right: 76px !important;
        margin-top: 80px !important;
    }

    .custom-mt-media-10 {
        margin-top: 0px;
    }

    .custom-padding-media-l {
        padding-left: 77px !important;
        padding-right: 30px !important;
    }

    .custom-padding-media-r {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .custom-height {
        min-height: 1722px;
    }
}
  
@media (min-width: 1200px) {
    .banner-button {
        position: absolute;
        bottom: 24vh;
        right: 10vw;
        z-index: 10;
        width: 20vw !important;
    }

    .banner-button_2 {
        position: absolute;
        bottom: 13vh;
        right: 10vw;
        /* z-index: 10; */
        width: 25vw !important;
    }

    .container-content {
        padding-left: 90px !important;
        padding-right: 90px !important;
        margin-top: 80px !important;
    }

    .custom-mt-media-10 {
        margin-top: 0px;
    }

    .custom-padding-media-l {
        padding-left: 120px !important;
        padding-right: 30px !important;
    }

    .custom-padding-media-r {
        padding-left: 20px !important;
        padding-right: 120px !important;
    }

    .custom-height {
        min-height: 1557px;
    }
}

.card-transparent {
    background-color: rgba(0, 0, 0, .5) !important;
    color: white !important;
}

.card-padding {
    padding: 5px !important;
}

.day-event {
    font-size: 18;
    color: darkcyan;
    font-weight: bold;
}

.margin-b-section {
    padding-bottom: 0px !important;
}

.description-event-calendar {
    margin-top: 35vh;
}

.section-custom {
    height: 60vh !important;
}

.custom-padding-left-0 {
    padding-left: 0px !important;
}

.custom-hr {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.img-login {
    background-image: url("../../../img/backgrounds/login.png");
    background-repeat: no-repeat;
    /* background-position: top left; */
    background-size: 100% 100%;
}

.img-register {
    background-image: url("../../../img/backgrounds/registro.png");
    background-repeat: no-repeat;
    /* background-position: top left; */
    background-size: 100% 100%;
}

.padding-30 {
    padding-left: 55px !important;
    padding-right: 55px !important;
    padding-top: 55px;
    padding-bottom: 55px;
}

.padding-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-top: 15px;
    padding-bottom: 15px;
}

.padding-15-r {
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-top: 15px;
}

.custom-btn {
    border: 1px solid #DE932E;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0px 0px !important;
}

body {
    background-color: #fafafa !important;
    /* margin: 0px !important; */
    overflow-x: hidden;
}

/* footer {
    margin-right: -60px !important;
} */

::-webkit-scrollbar{
    width: 8px;
    height: 8px;
    background: #fff;
    border: 1px solid #000;
}
  
::-webkit-scrollbar-thumb {
    background: #444;
    cursor: pointer;
}
  
::-webkit-scrollbar-thumb:hover {
    background: rgba(150,150,150,0.8);
    cursor: pointer;
}

.custom-mt-7 {
    margin-top: 7px;
}

.custom-mt-10 {
    margin-top: 10px;
}

.custom-mt-15 {
    margin-top: 15px;
}

.custom-mt-20 {
    margin-top: 20px;
}

.custom-mt-60 {
    margin-top: 60px;
}

.custom-mt-100 {
    margin-top: 100px;
}

.custom-card {
    /* background: linear-gradient(to bottom, #e7e7e7, #ffffff); */
    border: 1px solid #cccccc;
}

.custom-card-up {
    background: linear-gradient(to top, #e7e7e7, #ffffff);
}

.table-custom-primary th {
    border: 0px solid #205f97 !important;
    color: #424242;
    font-weight: bold;
    background: #ffffff;
    /* background: #DE932E -webkit-gradient(linear, left top, left bottom, from(#DE932E), to(#DE932E)) repeat-x;
    background: #DE932E -o-linear-gradient(top, #DE932E, #DE932E) repeat-x;
    background: #DE932E linear-gradient(180deg, #DE932E, #DE932E) repeat-x; */
    padding: 8px 30px 30px 8px !important;
}

.table-custom-primary td {
    border: 1px solid #ddd !important;
    padding: 8px !important;
}

.table-custom-primary tr {
    background-color: rgba(255, 255, 255, 1) !important;
    color: #000000;
}

.table-custom-primary tr:hover {
    background-color: rgb(208, 210, 245) !important;
    color: #29557c;
    cursor: pointer;
 }

.table-custom-primary tr:nth-of-type(odd) {
    background-color: rgb(236, 237, 255) !important;
}

.table-custom-primary tr:nth-of-type(odd):hover {
    background-color: rgba(208, 210, 245, 1) !important;
    color: #29557c;
    cursor: pointer;
}

.preload-ticket {
    backdrop-filter: blur(9px) !important;
    background-color: rgba(0, 0, 0, 0.5) ;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 999;
    left: 0px;
    top: 0px;
}

.preload-ticket-icon {
    display: grid;
    place-content: center;
    margin-top: 48vh;
    color: #cccccc;
}

.preload-ticket-icon_card {
    position:absolute;
    top: 45%;
    left: 43%;
    color: #cccccc;
}

.bg-blur{
    backdrop-filter: blur(9px) !important;
    background-color: rgba(255, 255, 255, 0.3) !important;
    border: 1px solid #c3c3c3 !important;
}

.bg-blur-9{
    backdrop-filter: blur(9px) !important;
    background-color: rgba(255, 255, 255, 0.9) !important;
    border: 1px solid #c3c3c3 !important;
}

.custom-card-header {
    background: linear-gradient(35deg, #077BD3 0, #0667BF 100%) !important;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
    /* background: linear-gradient(35deg, #0667BF 0, #DE932E 100%) !important; */
    
}

.custom-ml-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.custom-p-0 {
    padding: 16px !important;
}

.custom-p-32 {
    padding-right: 32px !important;
}

.custom-border-r {
    margin-top: 2px;
    margin-bottom: 2px;
    color: #424242;
    padding-left: 20px;
    border: 0px !important;
}

.custom-border-r-active {
    border-radius: 0px !important;
    background: #d0e4f6 !important;
    color: #0665d1;
    padding-left: 20px;
    border-left: 7px solid #0665d1 !important;
}

.silla-mesa-h {
    display: table-cell;
    flex-direction: row;
    height: 14px;
    min-width: 14px;
    max-width: 14px;
    border-radius: 7px 7px !important;
}

.silla-mesa-h-small{
    display: table-cell;
    flex-direction: row;
    height: 10px;
    min-width: 10px;
    max-width: 10px;
    border-radius: 5px 5px !important;
}

.silla-mesa-v {
    display: flex;
    height: 14px;
    min-width: 14px;
    max-width: 14px;
    border-radius: 7px 7px !important;
}

.silla-mesa-v-small{
    display: flex;
    height: 10px;
    min-width: 10px;
    max-width: 10px;
    border-radius: 5px 5px !important;
}

.mesa {
    width: 100%;
    height:100%;
    background-color: #d8d8d8;
    border-radius: 4px 4px;
    display: block;
}

.mesa-orden {
    display: table-cell;
    max-width: 100px;
    margin-right: 10px;
    border-top: 8px solid #ffffff;
    border-left: 2px solid #ffffff;
    border-right: 2px solid #ffffff;
}

.text-center {
    display: grid;
    place-content: center;
}

.butaca-select {
    background-color: #d8d8d8;
    border: 1px solid #424242;
    border-radius: 3px 3px;
    height: 18px;
    max-width: 18px;
    min-width: 18px;
    display: inline-table;
}

.butaca-name-select {
    position: absolute;
    left: 33px;
}

.butaca-select-cine {
    display: inline-table;
}

.butaca-default {
    display:table-cell;
    /* border-radius: 3px 3px; */
    cursor: pointer;
}

.butaca-disabled {
    display:table-cell;
    /* border-radius: 3px 3px; */
}

.butaca-disabled-ind {
    display:table-cell;
    /* border-radius: 3px 3px; */
    background-color: #ffffff;
    cursor: pointer;
}

.butaca-disabled-ind2 {
    display:table-cell;
    /* border-radius: 3px 3px; */
    background-color: #ffffff;
}

.butaca-default:hover {
    filter: brightness(70%);
}

.zona-default:hover {
    filter: brightness(70%);
    font-weight: bold;
}

.butaca-default-ind:hover {
    filter: brightness(70%);
}

.mesa-default {
    cursor: pointer;
}

.mesa-default:hover {
    filter: brightness(70%);
}



.butaca-num {
    margin-left: 2px;
    display:table-cell;
    text-align:center;
}

.auditorio-area {
    /* height: 54vh;
    overflow-y: auto;
    overflow-x: auto; */
    max-height: 48vh;
    overflow-y: auto;
    overflow-x: auto;
    padding: 10px;
    /* box-shadow: 2px 2px 3px #ccc; */
    /* border: 1px solid #ccc; */
    border-radius: 4px 4px;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #ffffff !important;
    background: linear-gradient(35deg, #11cdef 0, #1171ef 100%) !important;
}

.custom_bg-gradient-warning {
    background: linear-gradient(35deg, #DE932E 0, #DE932E 100%) !important; }

.nav2 {
    margin-bottom: 25px;
    text-align: center;
}
    
.linea-h {
    height: 5px;
    width: 100%;
    top: 23px;
    position: absolute;
}

.lh-disabled {
    background: #dddddd;
}

.lh-enabled {
    background: #077bd3;
}

.rccs__card--unknown > div {
    background-color: #DE932E !important;
}

.rccs__card--front, .rccs__card--back {
    background: #DE932E !important;
}

.card {
    border: 0px;
}

.card-title {
    background-color: #077bd3;
    text-align: center;
    border-radius: 30px 30px 0px 0px;
    min-height: 66px;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 23px;
    padding-top: 16px;
}

.card-footer {
    background-color: #ffffff !important;
    border-top: 0px !important;
}

.btn {
    border-radius: 13px 13px !important;
}

.dot2 {
    color: black;
    cursor: pointer;
    font-size: 20px;
    padding: 10px 15px 10px 15px;
    border-radius: 25px 25px;
   
    text-shadow: none;
    transition: opacity 1s ease,
        text-shadow 1s ease;
    will-change: opacity, text-shadow;
    margin-bottom: 20px;
}

.active2 {
    color: #fff;
    background: #077bd3;
    /* border: 20px solid #ffff; */
    height: 50px;
    width: 50px;
    position: absolute;
    border-radius: 50% 50% !important;
}

.dot2-disabled {
    color: #c9c9c9;
    background: #dddddd;
    /* border: 5px solid #dddddd; */
    position: absolute;
    height: 50px;
    width: 50px;
    border-radius: 50% 50% !important;
    margin-bottom: 20px;
}

.animated {
    animation-duration: .8192s;
    animation-fill-mode: backwards;
    /* transform-style: preserve-3d; */
}

 /** enterRight */
@keyframes enterRight {
    from {
      opacity: 0;
      transform: perspective(500px) translate3d(20%, 0, 0);
    }

    to {
      opacity: 1;
      transform: none;
    }
}

.enterRight {
    animation-name: enterRight;
}

/** enterLeft */
@keyframes enterLeft {
    from {
      opacity: 0;
      transform: perspective(500px) translate3d(-20%, 0, 0);
    }

    to {
      opacity: 1;
      transform: none;
    }
}

  .enterLeft {
  animation-name: enterLeft;
}

/** exitRight */
@keyframes exitRight {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
      transform: perspective(500px) translate3d(100%, 0, -100px);
    }
}

.exitRight {
    animation-name: exitRight;
}

/** exitLeft */
@keyframes exitLeft {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
      transform: perspective(500px) translate3d(-100%, 0, -100px);
    }
}

.exitLeft {
    animation-name: exitLeft;
}

/** intro */
@keyframes intro {
    from {
      opacity: 0;
      transform: perspective(500px) translate3d(0, 0, -50px);
    }

    to {
      opacity: 1;
      transform: none;
    }
}

.intro {
    animation: intro 1s ease-out;
}

.divider {
    margin-top: 1rem;
    margin-bottom: 1rem;
    height: 1px;
    overflow: hidden;
    border-bottom: 1px solid #0000000d;
    box-shadow: 1px 1px rgba(255,255,255, 0.3);
    margin-left: -21px;
    margin-right: -19px;
}

.custom-border {
    /* border: 1px solid #cccccc; */
    min-height: 200px;
    border-radius: 4px 4px;
    padding: 5px;
    /* box-shadow: 0 0 2px #cccccc; */
}

.custom-border_h {
    border: 1px solid #0000000d;
    border-radius: 4px 4px;
    padding: 5px;
}

.custom-background-ccc {
    background-color: #f1f1f1;
    /* box-shadow: 2px 2px 3px #ccc; */
}

.custom-background-fff {
    background-color: #ffffff;
}

.text-zona-select{
    padding: 5px;
    font-size: 16px;
}

.text-zona{
    padding: 5px;
    font-size: 12px;
}

.span-timer {
    background-color: #2b2b2b;
    color: #ffffff;
    padding: 5px;
    align-items: center;
    align-content: center;
    text-align: center;
    font-weight: bold;
    border-radius: 4px 4px;
}

.shoping-car {
    margin-top: 15px;
}

.container {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

area {
    cursor: pointer;
}

.qr-code-zona {
    background-color: #29557c;
    max-width: 158px;
    color: #ffffff;
    padding: 10px;
    border-radius: 6px 6px;
}

.qr-code-zona-xs {
    background-color: #29557c;
    max-width: 99px;
    color: #ffffff;
    padding: 5px;
    border-radius: 6px 6px;
}

.qr-code {
    background-color: #ffffff;
    padding: 5px;
    border-radius: 4px 4px;
}

.qr-code-xs {
    background-color: #ffffff;
    padding: 5px;
    border-radius: 4px 4px;
}

.qr-code-text {
    padding-top: 10px;
    padding-bottom: 10px;
}

.ticket-pay-left {
    border-radius: 4px 4px;
    /* border-top-left-radius: 4px 4px;
    border-bottom-left-radius: 4px 4px; */
    margin-bottom: 5px;
    background-color: #f2f2f2;
    color: #424242;
    padding: 10px;
    border-left: 1px solid #797979;
    border-top:  1px solid #797979;
    border-bottom:  1px solid #797979;
    border-right:  1px dashed #000000;
}

.ticket-pay-right {
    border-top:  1px solid #797979;
    border-bottom:  1px solid #797979;
    border-right:  1px solid #797979;
    margin-bottom: 5px;
    border-radius: 4px 4px;
    /* border-top-right-radius: 4px 4px;
    border-bottom-right-radius: 4px 4px; */
}

.custom-pr-10 {
    padding-right: 30px !important;
}

.spinner {
    width: 56px;
    height: 56px;
    display: grid;
    animation: spinner-plncf9 4s infinite;
}
 
.spinner::before,
.spinner::after {
    content: "";
    grid-area: 1/1;
    border: 9px solid;
    border-radius: 50%;
    border-color: #de932e #de932e #0000 #0000;
    mix-blend-mode: darken;
    animation: spinner-plncf9 1s infinite linear;
}
 
.spinner::after {
    border-color: #0000 #0000 #e4ebef #e4ebef;
    animation-direction: reverse;
}
 
@keyframes spinner-plncf9 {
    100% {
       transform: rotate(1turn);
    }
}

.detail-ticket {
    padding: 5px !important;
    margin-bottom: 10px;
    border: 1px solid #424242 !important;
    /* box-shadow: 1px 1px 2px #424242; */
}

.bread-crumb-custom {
    color:#cccccc !important;
}

.bread-crumb-custom > a {
    color:#424242 !important;
}

.custom-margin {
    max-width: 88% !important;
}

.soldout-pq{
    box-sizing:border-box;
    position:relative;
}

.soldout-pq::before{
    position:absolute;
    top:-9px;
    left:-36px;
    display: block;
    box-sizing:border-box;
    content:"Agotado";
    text-transform:uppercase;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 8px;
    text-align:center;
    font-weight: 700;
    color: #fff;

    background: transparent; 
    height:0;
    width:79px;                      
    border:12px solid transparent;
    border-bottom:12px solid rgb(196, 15, 15);
    line-height:14px;
    transform: rotate(-45deg);
}

.soldout{
    /* width:351px;
    height:170px; */
    box-sizing:border-box;
    /* margin: calc(50vh - 170px) auto; */
    position:relative;
}

.soldout::before{
    position:absolute;
    top:10px;
    left:-40px;
    display: block;
    box-sizing:border-box;
    content:"Agotado";
    text-transform:uppercase;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 13px;
    text-align:center;
    font-weight: 700;
    color: #fff;

    background: transparent; 
    height:0;
    width:150px;                      
    border:25px solid transparent;
    border-bottom:25px solid rgb(196, 15, 15);
    line-height:23px;
    transform: rotate(-45deg);
    /* box-shadow: 0px 3px 5px -3px #000; */
}

.soldout-gr{
    box-sizing:border-box;
    position:relative;
}

.soldout-gr::before{
    position:absolute;
    top:0px;
    left:-71px;
    display: block;
    box-sizing:border-box;
    content:"Agotado";
    text-transform:uppercase;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 26px;
    text-align:center;
    font-weight: 700;
    color: #fff;

    background: transparent; 
    height:0;
    width:240px;                      
    border:50px solid transparent;
    border-bottom:50px solid rgb(196, 15, 15);
    line-height:45px;
    transform: rotate(-45deg);
    /* box-shadow: 0px 3px 5px -3px #000; */
}

.comingsoon-pq{
    box-sizing:border-box;
    position:relative;
}

.comingsoon-pq::before{
    position:absolute;
    top:-9px;
    left:-36px;
    display: block;
    box-sizing:border-box;
    content:"Próximamente";
    text-transform:uppercase;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 8px;
    text-align:center;
    font-weight: 700;
    color: #fff;

    background: transparent; 
    height:0;
    width:79px;                      
    border:12px solid transparent;
    border-bottom:12px solid #f7b500;
    line-height:14px;
    transform: rotate(-45deg);
}

.comingsoon{
    /* width:351px;
    height:170px; */
    box-sizing:border-box;
    /* margin: calc(50vh - 170px) auto; */
    position:relative;
}

.comingsoon::before{
    position:absolute;
    top:10px;
    left:-40px;
    display: block;
    box-sizing:border-box;
    content:"Próximamente";
    text-transform:uppercase;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 13px;
    text-align:center;
    font-weight: 700;
    color: #fff;

    background: transparent; 
    height:0;
    width:150px;                      
    border:25px solid transparent;
    border-bottom:25px solid #f7b500;
    line-height:23px;
    transform: rotate(-45deg);
    /* box-shadow: 0px 3px 5px -3px #000; */
}

.comingsoon-gr{
    box-sizing:border-box;
    position:relative;
}

.comingsoon-gr::before{
    position:absolute;
    top:0px;
    left:-71px;
    display: block;
    box-sizing:border-box;
    content:"Próximamente";
    text-transform:uppercase;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 26px;
    text-align:center;
    font-weight: 700;
    color: #fff;

    background: transparent; 
    height:0;
    width:240px;                      
    border:50px solid transparent;
    border-bottom:50px solid #f7b500;
    line-height:45px;
    transform: rotate(-45deg);
    /* box-shadow: 0px 3px 5px -3px #000; */
}
/* -------------------------------- BUTACAS ------------------------------- */

@font-face {
    font-family: 'icomoon';
    src: url('./fonts/icomoon.eot?w6dqpf');
    src: url('./fonts/icomoon.eot?w6dqpf#iefix') format('embedded-opentype'),
        url('./fonts/icomoon.ttf?w6dqpf') format('truetype'),
        url('./fonts/icomoon.woff?w6dqpf') format('woff'),
        url('./fonts/icomoon.svg?w6dqpf#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
  
[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-butaca1 {
    cursor: pointer;
}

.icon-butaca1 .path1:before {
    content: "\e900";
}
.icon-butaca1 .path2:before {
    content: "\e901";
    margin-left: -0.8974609375em;
    filter: brightness(0.7);
    /* SILLA EXTERNA */
}
.icon-butaca1 .path3:before {
    content: "\e902";
    margin-left: -0.8974609375em;
    filter: brightness(0.7);
    /* SILLA INTERNA */
}
.icon-butaca1 .path4:before {
    content: "\e903";
    margin-left: -0.8974609375em;
    filter: brightness(0.5);
    /* PATA IZQUIERDA */
}
.icon-butaca1 .path5:before {
    content: "\e904";
    margin-left: -0.8974609375em;
    filter: brightness(0.5);
    /* PUÑO IZQUIERDO */
}
.icon-butaca1 .path6:before {
    content: "\e905";
    margin-left: -0.8974609375em;
    filter: brightness(0.5);
    /* PUÑO IZQUIERDO */
}
.icon-butaca1 .path7:before {
    content: "\e906";
    margin-left: -0.8974609375em;
    filter: brightness(0.5);
    /* PATA DERECHA */
}
.icon-butaca1 .path8:before {
    content: "\e907";
    margin-left: -0.8974609375em;
    filter: brightness(0.5);
    /* PUÑO DERECHO */
}
.icon-butaca1 .path9:before {
    content: "\e908";
    margin-left: -0.8974609375em;
    filter: brightness(0.5);
    /* PUÑO DERECHO */
}
.icon-silla1:before {
    content: "\e909";
}

/* ------------------------------------------------------------------------ */

/* ---------------------------  ICONOS  ----------------------------------- */
.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
  }
  .icon-calendar {
    width: 1.0078125em;
  }

/* ------------------------------------------------------------------------ */

.container {
    position: relative;
}

.tooltip_2 {
    position: absolute;
    color: #fff;
    padding: 10px;
    /* background: rgba(0,0,0,0.1); */
    transform: translate3d(-50%, -50%, 0);
    border-radius: 5px;
    pointer-events: none;
    z-index: 998;
    font-weight: bold;
}